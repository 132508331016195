<template>
    <div class="user-name text-truncate">
        <span>{{ name }}</span>
    </div>
</template>

<script>
export default {
    name: "UserName",
    props: {
        name: {
            type: String,
            required: true
        }
    }
}
</script>

<style lang="scss" scoped>
.user-name {
    span {
        font-size: 20px;

        @media(max-width: $sm) {
            font-size: 18px;
        }
    }
}
</style>
